import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';

const ThankYouView = () => {
    const theme = useTheme();
    return (
        <Container>
            <Box sx={{ overflowX: 'hidden' }}>
                <Main>
                    <Box>
                        <h3>
                            Awesome! You have taken the first step in a 5 step process to get your MAXIMUM Employee Retention Credit
                        </h3>
                        <h5>
                            Here are the next steps in the process:
                        </h5>
                        <ol>
                            <li>
                                You will receive a copy of our engagement letter at the email you specified. The
                                document is provided as an electronic agreement that you can sign on your mobile phone or compute. Please
                                review it carefully. If you agree with the terms please sign it electronically.<br />
                                <p>
                                    <b>There is no upfront payment required.</b>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Once the agreement is signed we will contact you to determine how you would like us to access your
                                    payroll system. We recommend creating a login/password for our team that can be used to run reports.
                                    <p><b>Our team is fully versed with most payroll systems. We can guide you through the process.</b></p>
                                </p>
                                <p>
                                    As an alternative you can also provide us with your credentials. We will treat your credentials with
                                    care and confidentiality. They will only be used to run the necessary reports required to compute
                                    your ERTC.
                                </p>
                            </li>
                            <li>
                                <p>
                                    We will inform you when we have all the data and work on your package has begun. We will also provide
                                    you with routine updates until the package is delivered to you.
                                </p>
                            </li>
                            <li>
                                <p>Once we have computed your credit and produced an <b>audit ready package</b>, we will provide 
                                this package to you electronically along with filing instructions.</p>
                            </li>
                            <li>
                                <b>Payment is due upon receipt of the final package with filing instructions.</b>
                            </li>
                        </ol>
                        <h5>
                            Thank you for giving us the opportunity to be of service to you. 2020 and 2021 have been difficult years especially
                            for small businesses. We hope this service will make things a little easier.
                        </h5>

                    </Box>
                    <Container>
                        { /* <Services /> */}
                    </Container>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >

                        <Container maxWidth={600}>
                            { /* <QuickStart /> */}
                        </Container>
                        <Container>
                            { /* <Features /> */}
                        </Container>
                        <Container>
                            { /* <Benefits /> */}
                        </Container>
                        { /* <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box> */ }
                    </Box>
                    <Container>
                        { /* <GetStarted /> */}
                    </Container>
                </Main>
            </Box>
        </Container>
    );
};

export default ThankYouView;
